import styled from 'styled-components';
import colors from '../colors';

const SectionTitle = styled.h3`
  font-family: Lato, serif;
  font-size: 22px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: 0.5px;
  max-width: 100%;
  margin-bottom: ${props => (props.margin ? '1em' : 0)};
  text-align: ${props => props.align || 'center'};
  color: ${props => (props.color ? props.color : colors.brand)};
`;

export default SectionTitle;
