// eslint-disable-next-line max-len
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
// imported from radumardale/react-keyboard-time-input
import React from 'react';
import PropTypes from 'prop-types';

import isTwelveHourTime from './lib/is-twelve-hour-time';
import replaceCharAt from './lib/replace-char-at';
import getGroupId from './lib/get-group-id';
import getGroups from './lib/get-groups';
import adder from './lib/time-string-adder';
import caret from './lib/caret';
import validate from './lib/validate';

const SILHOUETTE = '00:00:00:000 AM';

// isSeparator :: Char -> Bool
const isSeparator = char => /[:\s]/.test(char);

class TimeInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  state = {};

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate() {
    const { caretIndex: index } = this.state;
    if (index || index === 0) caret.set(this.input, index);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  format = val => {
    if (isTwelveHourTime(val)) {
      return val.replace(/^00/, '12');
    }
    return val.toUpperCase();
  };

  handleBlur = () => {
    if (this.mounted) this.setState({ caretIndex: null });
  };

  handleEscape = () => {
    if (this.mounted) this.input.blur();
  };

  handleTab = event => {
    const { value } = this.props;
    const start = caret.start(this.input);
    const groups = getGroups(value);
    let groupId = getGroupId(start);
    if (event.shiftKey) {
      if (!groupId) return;
      groupId--;
    } else {
      if (groupId >= groups.length - 1) return;
      groupId++;
    }
    event.preventDefault();
    let index = groupId * 3;
    if (value.charAt(index) === ' ') index++;
    if (this.mounted) this.setState({ caretIndex: index });
  };

  handleArrows = event => {
    const { value } = this.props;
    event.preventDefault();
    const start = caret.start(this.input);
    let amount = event.which === 38 ? 1 : -1;
    if (event.shiftKey) {
      amount *= 2;
      if (event.metaKey) amount *= 2;
    }
    const newValue = adder(value, getGroupId(start), amount);
    this.onChange(newValue, start);
  };

  silhouette = () => {
    const { value } = this.props;
    return value.replace(/\d/g, (val, i) => SILHOUETTE.charAt(i));
  };

  handleBackspace = event => {
    event.preventDefault();
    let { value } = this.props;
    let start = caret.start(this.input);
    let end = caret.end(this.input);
    if (!start && !end) return;
    let diff = end - start;
    const silhouette = this.silhouette();
    if (!diff) {
      if (value[start - 1] === ':') start--;
      value = replaceCharAt(value, start - 1, silhouette.charAt(start - 1));
      start--;
    } else {
      while (diff--) {
        if (value[end - 1] !== ':') {
          value = replaceCharAt(value, end - 1, silhouette.charAt(end - 1));
        }
        end--;
      }
    }
    if (value.charAt(start - 1) === ':') start--;
    this.onChange(value, start);
  };

  handleForwardSpace = event => {
    let { value } = this.props;
    event.preventDefault();
    let start = caret.start(this.input);
    const end = caret.end(this.input);
    if ((start === end) === value.length - 1) return;
    let diff = end - start;
    const silhouette = this.silhouette();
    if (!diff) {
      if (value[start] === ':') start++;
      value = replaceCharAt(value, start, silhouette.charAt(start));
      start++;
    } else {
      while (diff--) {
        if (value[end - 1] !== ':') {
          value = replaceCharAt(value, start, silhouette.charAt(start));
        }
        start++;
      }
    }
    if (value.charAt(start) === ':') start++;
    this.onChange(value, start);
  };

  // eslint-disable-next-line consistent-return
  handleKeyDown = event => {
    switch (event.which) {
      case 9: // Tab
        return this.handleTab(event);
      case 8: // Backspace
        return this.handleBackspace(event);
      case 46: // Forward
        return this.handleForwardSpace(event);
      case 27: // Esc
        return this.handleEscape(event);
      case 38: // Left
      case 40: // Right
        return this.handleArrows(event);
      default:
        break;
    }
  };

  handleChange = event => {
    let { value } = this.props;
    let newValue = this.input.value;
    let diff = newValue.length - value.length;
    let end = caret.start(this.input);
    let insertion;
    let start = end - Math.abs(diff);
    event.preventDefault();
    if (diff > 0) {
      insertion = newValue.slice(end - diff, end);
      while (diff--) {
        const oldChar = value.charAt(start);
        const newChar = insertion.charAt(0);
        if (isSeparator(oldChar)) {
          if (isSeparator(newChar)) {
            insertion = insertion.slice(1);
            start++;
          } else {
            start++;
            diff++;
            end++;
          }
        } else {
          value = replaceCharAt(value, start, newChar);
          insertion = insertion.slice(1);
          start++;
        }
      }
      newValue = value;
    } else {
      if (newValue.charAt(start) === ':') start++;
      // apply default to selection
      let result = value;
      for (let i = start; i < end; i++) {
        result = replaceCharAt(result, i, newValue.charAt(i));
      }
      newValue = result;
    }
    if (validate(newValue)) {
      if (newValue.charAt(end) === ':') end++;
      this.onChange(newValue, end);
    } else {
      const caretIndex = value.length - (newValue.length - end);
      if (this.mounted) this.setState({ caretIndex });
    }
  };

  onChange = (str, caretIndex) => {
    const { onChange } = this.props;
    if (onChange) onChange(this.format(str));
    if (this.mounted && typeof caretIndex === 'number') this.setState({ caretIndex });
  };

  render() {
    const { className: origClassName, value } = this.props;
    let className = 'TimeInput';
    if (className) {
      className += ` ${origClassName}`;
    }
    return (
      <div className={className}>
        <input
          className="TimeInput-input"
          ref={input => {
            this.input = input;
          }}
          type="text"
          value={this.format(value)}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    );
  }
}

export default TimeInput;
