import React from 'react';
import TextInput from './TextInput';

export default class DateInput extends React.Component {
  state = {
    invalid: false,
    errorText: '',
  };

  validate = value => {
    if (/\d{4}-\d{2}-\d{2}/.test(value)) {
      this.setState({ invalid: false, errorText: '' });
    } else {
      this.setState({ invalid: true, errorText: 'Invalid date format' });
    }
  };

  render() {
    const { invalid, errorText } = this.state;
    return <TextInput invalid={invalid} errorText={errorText} validate={this.validate} {...this.props} />;
  }
}
