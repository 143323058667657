import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import colors from '../colors';
import MediaQueries from '../MediaQueries';

const style = {
  overlay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    overflowY: 'auto',
    zIndex: 1,
  },
};

const StyledReactModal = styled(ReactModal)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 0;

  ${MediaQueries.notMobile} {
    position: relative;
    margin-top: 50px;
  }

  > div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 1;

    ${MediaQueries.notMobile} {
      position: initial;
      margin: 0 auto;
      border: 1px solid gray;
    }
  }
`;

const Chevron = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  padding: 5px;
  z-index: 2;
  color: ${colors.gray};
`;

ReactModal.setAppElement('#__next');

class Modal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  componentDidMount() {
    const { isOpen } = this.props;

    if (isOpen) {
      this.attachBodyStyle();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.attachBodyStyle();
    } else if (prevProps.isOpen && !isOpen) {
      this.detachBodyStyle();
    }
  }

  componentWillUnmount() {
    this.detachBodyStyle();
  }

  attachBodyStyle = () => {
    if (global.document) {
      global.document.body.style.overflow = 'hidden';
    }
  };

  detachBodyStyle = () => {
    if (global.document) {
      global.document.body.style.overflow = 'auto';
    }
  };

  render() {
    const { onRequestClose, children } = this.props;
    return (
      <StyledReactModal style={style} {...this.props}>
        <Chevron onClick={onRequestClose}>
          <FontAwesomeIcon icon={faTimes} />
        </Chevron>
        {children}
      </StyledReactModal>
    );
  }
}

export default Modal;
