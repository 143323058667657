import config from '@cardash/config';

function isSfacOrganization(id) {
  if (!id || !config.sfacOrganizationId) {
    return false;
  }
  return id === config.sfacOrganizationId;
}

export default isSfacOrganization;
