import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../colors';

const ProgressContainer = styled.div`
  width: 291px;
  max-width: 100%;
  height: 7px;
  border-radius: 3.5px;
  background-color: #e4e6ee;
`;

const ProgressBar = styled.div`
  height: 100%;
  border-radius: 3.5px;
  background-color: ${colors.brand};
  transition: width 0.4s ease-out;
`;

const Progress = ({ progress }) => (
  <ProgressContainer>
    <ProgressBar style={{ width: `${progress * 100}%` }} />
  </ProgressContainer>
);

Progress.propTypes = {
  progress: PropTypes.number,
};

Progress.defaultProps = {
  progress: 0.2,
};

export default Progress;
