import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Error from '../../Error';
import { StyledSelect } from './StyledComponents';
import { GetOrganizationsQuery } from '../../graphql';
import useMainContext from '../../../hooks/main-provider.jsx';
import { actorRanks } from '../../../../config/common';
import { useSession } from 'next-auth/react';

const GetOrganizations = gql`
  ${GetOrganizationsQuery}
`;

const Organizations = () => {
  const { data: session } = useSession();
  const user = session?.user;
  const { data, loading, error } = useQuery(GetOrganizations, { skip: !user });

  const { selectedOrganization, updateSelectedOrganization, viewModeDataLoading } = useMainContext();

  const organizations = useMemo(() => {
    return (data && data.organizations) || [];
  }, [data]);

  const options = useMemo(() => {
    return organizations.map(org => ({
      label: org.displayName,
      value: org.id,
    }));
  }, [organizations]);

  const selectedValue = useMemo(() => {
    const organization = selectedOrganization && organizations.find(o => o.id === Number(selectedOrganization.id));

    if (organization) {
      return { label: organization.displayName, value: organization.id };
    }
    return null;
  }, [selectedOrganization, organizations]);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="d-flex w-100 mb-2" style={{ maxWidth: 250, margin: '10px 15px' }}>
      <p className="text-light mb-0 me-2">Organization:</p>
      {user?.rank <= actorRanks.organizationOwner && organizations.length > 1 ? (
        <StyledSelect
          classNamePrefix="side-menu-select"
          isClearable={user.rank <= actorRanks.masterAdmin}
          onChange={selectedItem => {
            const org = selectedItem && organizations.find(o => o.id === Number(selectedItem.value));
            updateSelectedOrganization(org && org.id);
          }}
          isLoading={loading || viewModeDataLoading}
          value={selectedValue}
          options={options}
        />
      ) : (
        <p className="text-light mb-0 me-2">{selectedValue ? selectedValue.label : ''}</p>
      )}
    </div>
  );
};

export default Organizations;
