import { useMemo } from 'react';
import config from '@cardash/config';
import { Spinner } from 'reactstrap';
import { useSession } from 'next-auth/react';
import styled from 'styled-components';
import useMainContext from '../../hooks/main-provider';
import { checkRouteAuthorization } from '../../utils/auth';
import MenuSidebar from '../Buttons/MenuSidebar';
import TopNavBar from '../TopNavBar';

const SpinnerContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Layout = ({ children }) => {
  const { viewMode } = useMainContext();
  const { data: session, status } = useSession();
  const { routes } = config;

  const user = session?.user;

  const sideBarPages = useMemo(
    () =>
      Object.values(routes || {}).filter(
        route =>
          route.pageName &&
          checkRouteAuthorization(route.path, user?.type, viewMode) &&
          route.navBarItemInViewModes.findIndex(mode => mode === (viewMode || config.viewModes.Master)) !== -1
      ),
    [routes, user, viewMode]
  );

  if (status === 'loading') {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
      {user && <TopNavBar />}
      <div className={user ? 'd-flex' : 'block'}>
        {user && <MenuSidebar pages={sideBarPages} />}
        <div className="d-flex flex-column flex-fill align-items-center">
          <div className="d-flex justify-content-center w-100 px-5 flex-fill " style={{ maxWidth: 1440 }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
