const yellowColor = '#ffc107';
const greenColor = '#28a745';
const lightRedColor = '#FF6666';
const redColor = '#cc0000';

const capacityThresholds = {
  darkRed: { max: 0 },
  red: { min: 0, max: 10 },
  yellow: { min: 10, max: 20 },
};

const vehicleOnSiteThresholds = {
  yellow: { min: 0.7, max: 0.9 },
  green: { min: 0.9, max: Infinity },
};

const techsLiftsThresholds = {
  yellow: { min: 0.7, max: 1 },
  green: { min: 1, max: Infinity },
};

const avgTimeOnSiteThresholds = {
  green: { min: 0, max: 3 },
  yellow: { min: 3, max: 5 },
};

const oldestVehicleOnSiteThresholds = {
  green: { min: 0, max: 10 },
  yellow: { min: 10, max: 30 },
};

const openDaysThresholds = {
  yellow: { min: 6, max: 7 },
  green: { max: 7 },
};

const maxAppointmentTechsThresholds = {
  green: { min: 2, max: Infinity },
  yellow: { min: 1.7, max: 2 },
};

const getCapacityColor = value => {
  if (value < capacityThresholds.darkRed.max) {
    return redColor;
  } else if (value >= capacityThresholds.red.min && value <= capacityThresholds.red.max) {
    return lightRedColor;
  } else if (value > capacityThresholds.yellow.min && value <= capacityThresholds.yellow.max) {
    return yellowColor;
  } else {
    return greenColor;
  }
};

const getVehicleOnSiteValueColor = value => {
  if (value >= vehicleOnSiteThresholds.yellow.min && value <= vehicleOnSiteThresholds.yellow.max) {
    return yellowColor;
  } else if (value > vehicleOnSiteThresholds.green.min) {
    return greenColor;
  } else {
    return redColor;
  }
};

const getAvgTimeOnSiteValueColor = value => {
  if (value >= avgTimeOnSiteThresholds.green.min && value <= avgTimeOnSiteThresholds.green.max) {
    return greenColor;
  } else if (value > avgTimeOnSiteThresholds.yellow.min && value <= avgTimeOnSiteThresholds.yellow.max) {
    return yellowColor;
  } else {
    return redColor;
  }
};

const getOldestVehicleOnSiteValueColor = value => {
  if (value >= oldestVehicleOnSiteThresholds.green.min && value < oldestVehicleOnSiteThresholds.green.max) {
    return greenColor;
  } else if (value >= oldestVehicleOnSiteThresholds.yellow.min && value < oldestVehicleOnSiteThresholds.yellow.max) {
    return yellowColor;
  } else {
    return redColor;
  }
};

const getTechsLiftsValueColor = value => {
  if (value >= techsLiftsThresholds.yellow.min && value < techsLiftsThresholds.yellow.max) {
    return yellowColor;
  } else if (value >= techsLiftsThresholds.green.min) {
    return greenColor;
  } else {
    return redColor;
  }
};

const getOpenDaysValueColor = value => {
  if (value >= openDaysThresholds.yellow.min && value < openDaysThresholds.yellow.max) {
    return yellowColor;
  } else if (value === openDaysThresholds.green.max) {
    return greenColor;
  } else {
    return redColor;
  }
};

const getMaxAppointmentTechsValueColor = value => {
  if (value >= maxAppointmentTechsThresholds.green.min && value < maxAppointmentTechsThresholds.green.max) {
    return greenColor;
  } else if (value >= maxAppointmentTechsThresholds.yellow.min && value < maxAppointmentTechsThresholds.yellow.max) {
    return yellowColor;
  } else {
    return redColor;
  }
};

const getTextColor = value => {
  if (value >= 10 && value < 20) {
    return '#000'; // black for yellow bar
  } else {
    return '#fff'; // white for green or red bar
  }
};

export const getColorCodes = {
  getCapacityColor,
  getVehicleOnSiteValueColor,
  getAvgTimeOnSiteValueColor,
  getOldestVehicleOnSiteValueColor,
  getTechsLiftsValueColor,
  getOpenDaysValueColor,
  getMaxAppointmentTechsValueColor,
  getTextColor,
};
