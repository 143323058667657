import common from './common';
import development from './development';
import production from './production';
import staging from './staging';

const config = {
  common,
  development,
  production,
  staging,
};

export default config;
