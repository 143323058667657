import styled from 'styled-components';
import { BodyStyle, smallBodySize, largeBodySize } from '../../Typography/Body';
import colors from '../../colors';
import { MediaQueries } from '../../index';

const getBorderColor = (invalid, isFocused) => {
  if (invalid) return colors.error;
  if (isFocused) return colors.brand;
  return colors.lightGray;
};

export const InputContainer = styled.div`
  border: 1px solid ${props => getBorderColor(props.invalid, props.isFocused, props)};
  border-radius: 4px;
  height: ${props => (props.compact ? '24px' : '47px')};
  transition: all 0.2s ease;
  flex-direction: row;
  display: flex;
`;

const getPointer = disabled => (disabled ? 'default' : 'initial');

const getColor = disabled => (disabled ? colors.slateGray : colors.darkGray);

export const StyledInput = styled.input`
  ${BodyStyle}
  ${props => (props.compact ? `${smallBodySize}` : '')}
  cursor: ${props => getPointer(props.disabled)};
  color: ${props => getColor(props.disabled)};
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 4px;
  padding-left: ${props => (props.hasLeftIcon ? '45px' : '15px')};
  padding-right: ${props => (props.hasRightIcon ? '45px' : '15px')};
  padding-top: 9px;
  padding-bottom: 9px;

  ${props =>
    props.smallOnMobile
      ? `
    ${smallBodySize}

    ${MediaQueries.notMobile} {
      ${largeBodySize}
    }
  `
      : ''}

  :focus {
    outline: ${colors.brand};
    border: none;
    box-shadow: 0 0 10px ${colors.brand};
  }

  ::placeholder {
    color: ${colors.slateGray};
  }
`;

export const InvalidTooltipContainer = styled.div`
  color: ${colors.error};
  margin-top: 3px;
  font-size: 12px;
  ${BodyStyle}
`;
