import { useMemo, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useSession } from 'next-auth/react';
import { initApollo } from './initApollo';

export const WithApolloClient = ({ children }) => {
  // const { data: session, error } = useSWR('session', fetchSession);
  const { data: session } = useSession();
  const [apolloClient, setApolloClient] = useState(initApollo());
  useMemo(() => {
    if (session?.user) {
      const authTokens = {
        jwtToken: session?.user?.jwtToken,
        csrfToken: session?.user?.csrfToken,
        refreshToken: session?.user?.refreshToken,
      };
      setApolloClient(initApollo({}, authTokens));
    }
  }, [session?.user]);

  if (!apolloClient) {
    return <div>Loading...</div>;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
