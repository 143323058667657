import styled from 'styled-components';

import MediaQueries from '../../MediaQueries';

const Spacer = styled.div`
  width: 100%;
  height: 25px;
  ${MediaQueries.notMobile} {
    height: 50px;
  }
`;

export default Spacer;
