import React from 'react';
import styled from 'styled-components';
import colors from '../../colors';
import Body from '../../Typography/Body';

const flat = `
  color: ${colors.brand};
  background-color: #fff;
`;

const flatHover = `
  opacity: .90;
`;

const stdHover = `
  background-color: ${colors.hover};
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  white-space: noWrap;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  height: 47px;
  width: ${props => (!props.small ? '125px' : '')};
  max-width: 100%;
  padding: 10px;
  color: #fff;
  background-color: ${colors.brand};
  border: 1px solid ${colors.brand};
  ${props => (props.flat ? flat : '')}
  &:hover {
    box-shadow: 4px 4px 4px -2px rgba(0, 0, 0, 0.2);
    ${props => (props.flat ? flatHover : stdHover)}
  }
  &:disabled {
    background-color: ${colors.lilac};
    border-color: ${colors.lilac};

    &:hover {
      box-shadow: none;
      cursor: auto;
    }
  }
`;

class SecondaryButton extends React.Component {
  renderChildren = props => {
    const { children } = props;
    if (typeof children === 'string') {
      return (
        <Body
          style={{ marginBottom: 0, textTransform: 'uppercase' }}
          small
          color={props.flat && !props.disabled ? colors.brand : '#fff'}
        >
          {children}
        </Body>
      );
    }
    return children;
  };

  render() {
    return <Button {...this.props}>{this.renderChildren(this.props)}</Button>;
  }
}

export default SecondaryButton;
