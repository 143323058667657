import React from 'react';
import PropTypes from 'prop-types';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, UncontrolledDropdown } from 'reactstrap';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';
import { faCaretDown, faCaretUp, faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import colors from '../colors';
import Error from '../Error';
import Loading from '../Loading';
import Body from '../Typography/Body';
import Row from '../LayoutComponents/Row';
import Column from '../LayoutComponents/Column';

import { GetVehicleMakesQuery, GetVehicleModelFromYearMakesQuery, GetVehicleYearsQuery } from '../graphql';

const DeleteIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  height: 0;
`;

const AncorIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  height: 0;
`;

const CloseButton = styled.button`
  margin: 3px;
`;

const GetVehicleYears = gql`
  ${GetVehicleYearsQuery}
`;
const GetVehicleMakes = gql`
  ${GetVehicleMakesQuery}
`;
const GetVehicleModelFromYearMakes = gql`
  ${GetVehicleModelFromYearMakesQuery}
`;

class PriceParamRecordInput extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    invalid: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: '',
    invalid: false,
  };

  transformToSingleValue = val => {
    const { onChange } = this.props;
    const [, high] = val;

    onChange(high);
  };

  transformToRange = val => {
    const { onChange } = this.props;
    onChange([val, val]);
  };

  render() {
    const { value, invalid, onChange } = this.props;
    return (
      <div>
        <Row style={{ alignItems: 'center' }}>
          {value instanceof Array && (
            <React.Fragment>
              <Column>
                <Row>
                  <Input
                    compact
                    style={{ width: '100px' }}
                    placeholder="not added"
                    invalid={invalid}
                    onChange={e => onChange([e.target.value, value[1]])}
                    value={value[0]}
                  />
                  <span style={{ marginLeft: '5px', marginRight: '5px' }}>-</span>
                  <Input
                    compact
                    style={{ width: '100px' }}
                    invalid={invalid}
                    onChange={e => onChange([value[0], e.target.value])}
                    value={value[1]}
                  />
                  <Button
                    color="primary"
                    size="sm"
                    outline
                    style={{
                      minWidth: '125px',
                      cursor: 'pointer',
                      paddingLeft: '5px',
                      marginLeft: '10px',
                    }}
                    onClick={() => this.transformToSingleValue(value)}
                  >
                    Change to single value
                  </Button>
                </Row>
                <Row>{invalid && <div style={{ color: 'red', fontSize: 12 }}>Invalid Range</div>}</Row>
              </Column>
            </React.Fragment>
          )}
          {(typeof value === 'string' || typeof value === 'number') && (
            <React.Fragment>
              <Input
                compact
                style={{ width: '100px' }}
                onChange={e => onChange(e.target.value.replace(/[^0-9\.]/g, ''))}
                value={value}
              />
              <Button
                color="primary"
                size="sm"
                rounded="true"
                outline
                style={{
                  minWidth: '125px',
                  cursor: 'pointer',
                  paddingLeft: '5px',
                  marginLeft: '10px',
                }}
                onClick={() => this.transformToRange(value)}
              >
                Change to range
              </Button>
            </React.Fragment>
          )}
        </Row>
      </div>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp,react/prefer-stateless-function
class PriceParamInput extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
    isDollarSign: PropTypes.bool,
    invalid: PropTypes.bool,
    addOverridesButtonPosition: PropTypes.number,
    addOverrideButtonText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onOverrideChange: PropTypes.func.isRequired,
    sfacId: PropTypes.number,
  };

  static defaultProps = {
    sfacId: null,
    value: {
      default: '',
    },
    addOverridesButtonPosition: 0,
    addOverrideButtonText: '',
    isDollarSign: false,
    invalid: false,
  };

  state = {
    newOverride: null,
    newOverrideValue: 0,
    isShowOverrides: false,
  };

  render() {
    const {
      value,
      onChange,
      onOverrideChange,
      sfacId,
      isDollarSign,
      invalid,
      addOverridesButtonPosition,
      addOverrideButtonText,
    } = this.props;
    const { newOverride, newOverrideValue, isShowOverrides } = this.state;

    const defaultValue = value.default || '';
    const overrideKeys = Object.keys(value)
      .filter(k => k !== 'default')
      .filter(k => k !== 'paramVersion');
    const buttonPositionWithOverrides = overrideKeys.length !== 0 ? 35 : 0;
    const addOverridePosition = addOverridesButtonPosition
      ? addOverridesButtonPosition + buttonPositionWithOverrides
      : buttonPositionWithOverrides;
    const addOverrideText = addOverrideButtonText || 'Add New Override';
    return (
      <React.Fragment>
        <Row
          style={{
            justifyContent: 'space-between',
            position: 'relative',
            marginBottom: 10,
          }}
        >
          <Column>
            <Row style={{ alignItems: 'center' }}>
              {isDollarSign && (
                <FontAwesomeIcon
                  icon={faDollarSign}
                  style={{
                    marginLeft: 10,
                    marginTop: 2,
                    marginRight: 5,
                    color: colors.gray,
                  }}
                />
              )}
              <PriceParamRecordInput
                value={defaultValue}
                invalid={invalid}
                onChange={v =>
                  onChange({
                    ...value,
                    default: v,
                  })
                }
              />
            </Row>
            {overrideKeys.length !== 0 && (
              <Row>
                <Label style={{ marginLeft: 10, marginTop: 5, fontWeight: 'bold' }} small>
                  Vehicle Specific Pricing
                </Label>
                <AncorIcon onClick={() => this.setState({ isShowOverrides: !isShowOverrides })}>
                  <FontAwesomeIcon
                    icon={isShowOverrides ? faCaretUp : faCaretDown}
                    style={{
                      marginLeft: 10,
                      marginTop: 10,
                      color: colors.gray,
                    }}
                  />
                </AncorIcon>
              </Row>
            )}
          </Column>
          <Column
            style={{
              alignItems: 'flex-end',
              position: 'absolute',
              right: 0,
              bottom: addOverridePosition,
            }}
          >
            {!newOverride && (
              <Button
                color="link"
                style={{
                  cursor: 'pointer',
                  margin: 10,
                  padding: 5,
                  maxWidth: '270px',
                  color: 'dimgray',
                  textDecoration: 'none',
                  fontWeight: '400',
                }}
                onClick={() =>
                  this.setState({
                    newOverride: {
                      year: '',
                      make: '',
                      model: '',
                      value: 0,
                    },
                  })
                }
              >
                {addOverrideText}
              </Button>
            )}
          </Column>
        </Row>
        {isShowOverrides &&
          overrideKeys.map(k => (
            <div key={k} style={{ paddingLeft: 25, paddingTop: 15 }}>
              <Row style={{ alignItems: 'center' }}>
                <Body
                  style={{
                    width: '175px',
                    paddingRight: '5px',
                    marginLeft: '10px',
                  }}
                  small
                >
                  {k.split('::').join(' ')}
                </Body>
                <PriceParamRecordInput
                  value={value[k]}
                  onChange={v =>
                    onChange({
                      ...value,
                      [k]: v,
                    })
                  }
                />
                <DeleteIcon
                  onClick={() => {
                    delete value[k];
                    onChange(value);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      marginLeft: 10,
                      marginTop: -5,
                      color: colors.gray,
                    }}
                  />
                </DeleteIcon>
              </Row>
            </div>
          ))}

        {newOverride && (
          <div style={{ margin: 10, padding: 5 }}>
            <Row>
              <Query query={GetVehicleYears}>
                {({ loading, data, error }) => {
                  if (loading) return <Loading />;
                  if (error) return <Error error={error} />;
                  if (data) {
                    return (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          style={{
                            minWidth: 70,
                            marginRight: '10px',
                            textAlign: 'end',
                            backgroundColor: '#FFFFFF',
                            borderColor: '#ced4da',
                            color: '#212529',
                          }}
                          caret
                        >
                          {newOverride.year ? newOverride.year : 'Select Year'}
                        </DropdownToggle>
                        <DropdownMenu>
                          {[
                            {
                              label: 'Select Year',
                              value: '',
                            },
                          ]
                            .concat(
                              data.getVehicleYears.map(y => ({
                                label: y.year,
                                value: y.year,
                              }))
                            )
                            .map(item => {
                              return (
                                <DropdownItem
                                  key={item.value}
                                  style={{ minHeight: 30 }}
                                  active={newOverride.year === item.value}
                                  onClick={() => {
                                    onOverrideChange({
                                      year: item.value,
                                    });
                                    this.setState(prevState => ({
                                      newOverride: {
                                        ...prevState.newOverride,
                                        year: item.value,
                                      },
                                    }));
                                  }}
                                >
                                  {item.label}
                                </DropdownItem>
                              );
                            })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    );
                  }
                  return null;
                }}
              </Query>
              <Query query={GetVehicleMakes} variables={{ sfacId, year: parseInt(newOverride.year, 10) }}>
                {({ loading, data, error }) => {
                  if (loading) return <Loading />;
                  if (error) return <Error error={error} />;
                  if (data) {
                    return (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          style={{
                            minWidth: 70,
                            marginRight: '10px',
                            textAlign: 'end',
                            backgroundColor: '#FFFFFF',
                            borderColor: '#ced4da',
                            color: '#212529',
                          }}
                          caret
                        >
                          {newOverride.make ? newOverride.make : 'Select Make'}
                        </DropdownToggle>
                        <DropdownMenu>
                          {[
                            {
                              label: 'Select Make',
                              value: '',
                            },
                          ]
                            .concat(
                              data.getVehicleBrands.map(y => ({
                                label: y.brand,
                                value: y.brand,
                              }))
                            )
                            .map(item => {
                              return (
                                <DropdownItem
                                  key={item.value}
                                  style={{ minHeight: 30 }}
                                  active={newOverride.make === item.value}
                                  onClick={() => {
                                    onOverrideChange({
                                      make: item.value,
                                    });
                                    this.setState(prevState => ({
                                      newOverride: {
                                        ...prevState.newOverride,
                                        make: item.value,
                                      },
                                    }));
                                  }}
                                >
                                  {item.label}
                                </DropdownItem>
                              );
                            })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    );
                  }
                  return null;
                }}
              </Query>
              <Query
                query={GetVehicleModelFromYearMakes}
                variables={{
                  year: parseInt(newOverride.year, 10) || 0,
                  make: newOverride.make || '',
                }}
              >
                {({ loading, data, error }) => {
                  if (loading) return <Loading />;
                  if (error) return <Error error={error} />;
                  if (data) {
                    return (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          style={{
                            minWidth: 70,
                            marginRight: '10px',
                            textAlign: 'end',
                            backgroundColor: '#FFFFFF',
                            borderColor: '#ced4da',
                            color: '#212529',
                          }}
                          caret
                        >
                          {newOverride.model ? newOverride.model : 'Select Model'}
                        </DropdownToggle>
                        <DropdownMenu>
                          {[
                            {
                              label: 'Select Model',
                              value: '',
                            },
                          ]
                            .concat(
                              data.getVehicleModelFromYearMakes.map(y => ({
                                label: y.model,
                                value: y,
                              }))
                            )
                            .map(item => {
                              return (
                                <DropdownItem
                                  key={item.value.model}
                                  style={{ minHeight: 30 }}
                                  active={newOverride.model === item.value.model}
                                  onClick={() => {
                                    onOverrideChange({
                                      model: item.value.model,
                                    });
                                    this.setState(prevState => ({
                                      newOverride: {
                                        ...prevState.newOverride,
                                        model: item.value.model,
                                      },
                                    }));
                                  }}
                                >
                                  {item.label}
                                </DropdownItem>
                              );
                            })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    );
                  }
                  return null;
                }}
              </Query>
            </Row>
            <Row style={{ alignItems: 'center', marginTop: 10 }}>
              <Body small style={{ marginTop: 5, marginRight: 5 }}>
                {newOverride.year ? newOverride.year : ''} {newOverride.make ? newOverride.make : ''}{' '}
                {newOverride.model ? newOverride.model : ''}
              </Body>
              {(!!newOverride.year || !!newOverride.make || !!newOverride.model) && (
                <PriceParamRecordInput
                  value={newOverrideValue}
                  onChange={v => {
                    onOverrideChange({
                      value: v,
                    });
                    this.setState({
                      newOverrideValue: v,
                    });
                  }}
                />
              )}
              <Button
                size="sm"
                outline
                color="primary"
                style={{ margin: '0 10px' }}
                disabled={!newOverride.year && !newOverride.make && !newOverride.model}
                onClick={() => {
                  const newOverrideData = {};
                  newOverrideData[
                    [
                      newOverride.year ? newOverride.year : null,
                      newOverride.make ? newOverride.make : null,
                      newOverride.model ? newOverride.model : null,
                    ].join('::')
                  ] = newOverrideValue;
                  onChange({
                    ...value,
                    ...newOverrideData,
                  });
                  this.setState({
                    newOverride: null,
                    newOverrideValue: 0,
                  });
                }}
              >
                Save Override
              </Button>

              <CloseButton
                className="btn-close"
                onClick={() =>
                  this.setState({
                    newOverride: null,
                    newOverrideValue: 0,
                  })
                }
              />
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default PriceParamInput;
