import PropTypes from 'prop-types';
import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

Column.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};

Column.defaultProps = {
  style: {},
};

export default Column;
