import * as Sentry from '@sentry/nextjs';

const config = require('@cardash/config').default || require('@cardash/config');
const { ApolloClient, ApolloLink, InMemoryCache } = require('@apollo/client');
const { onError } = require('apollo-link-error');
const { setContext } = require('@apollo/client/link/context');
const { HttpLink } = require('apollo-link-http');
const fetch = require('isomorphic-unfetch');
const Toastr = require('../components/Toastr');
const { logout } = require('../auth');

const { refreshJwtToken } = require('../auth');

const apolloClientData = {
  apolloClient: null,
  jwtToken: '',
  csrfToken: '',
};

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

const setAuthorizationMiddlewareLinkFactory = (jwtToken = null, csrfToken) =>
  setContext(() => {
    if (csrfToken) {
      return {
        headers: {
          ...(jwtToken ? { 'cardash-jwt': jwtToken } : {}),
          authorization: `Token ${csrfToken}`,
        },
      };
    }
    return null;
  });

const errorLinkFactory = (refreshToken, csrfToken) =>
  onError(({ operation, networkError, forward }) => {
    Sentry.captureException({
      operation,
      networkError,
    });
    // Attempt to refresh their token
    if (csrfToken && networkError && networkError.statusCode === 401) {
      // eslint-disable-next-line no-console
      /* console.log(
         '-------------- Error FACTORY 401 -------------- ',
         networkError
       );*/
      /*  try {
          await logout();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('-------------- Error logging out -------------- ', e);
        }*/
      // We have to use an Observable because this callback return a promise
      /*return new Observable(observer => {
       refreshJwtToken(refreshToken, csrfToken).then(payload => {
           const oldHeaders = operation.getContext().headers;
           operation.setContext({
             headers: {
               ...oldHeaders,
               authorization: `Token ${payload.csrfToken}`,
             },
           });

           const subscriber = {
             next: observer.next.bind(observer),
             error: observer.error.bind(observer),
             complete: observer.complete.bind(observer),
           };

           return forward(operation).subscribe(subscriber);
         });
      });*/
    }
    return null;
  });

const consoleLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    if (
      data &&
      data.errors &&
      data.errors[0] &&
      data.errors[0].extensions &&
      data.errors[0].extensions.code === 'DEPRECATED_ACCESS_LEVEL'
    ) {
      Toastr.warning(
        'The access level of the account had been changed, you will be logged out in 10 secs. Please log in back to continue.',
        { timeOut: 10000 }
      );
      setTimeout(async () => {
        await logout();
        window.location.href = '/';
      }, 10000);
    }
    return data;
  });
});

/**
 * @param {Object} initialState
 * @param {{ jwtToken?: string, csrfToken?: string, refreshToken?: string, }} authTokens
 * @returns {ApolloClient}
 */
const create = (initialState, authTokens = {}) => {
  const { jwtToken, csrfToken, refreshToken } = authTokens;
  const link = ApolloLink.from([
    ...(csrfToken ? [errorLinkFactory(refreshToken, csrfToken)] : []),
    ...[setAuthorizationMiddlewareLinkFactory(jwtToken, csrfToken)],
    ...(csrfToken ? [consoleLink] : []),
    new HttpLink({
      uri: config.graphql.url,
      credentials: 'include',
    }),
  ]);

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link,
    cache: new InMemoryCache({ addTypename: false }).restore(initialState || {}),
  });
};

/**
 * @param {Object} initialState
 * @param {{ jwtToken: string, csrfToken: string, refreshToken: string, }} authTokens
 * @returns {ApolloClient}
 */
export const initApollo = (initialState = {}, authTokens = {}) => {
  if (
    !apolloClientData.apolloClient ||
    (authTokens.jwtToken && apolloClientData.jwtToken !== authTokens.jwtToken) ||
    (authTokens.csrfToken && apolloClientData.csrfToken !== authTokens.csrfToken)
  ) {
    apolloClientData.apolloClient = create(initialState, authTokens);
    apolloClientData.jwtToken = authTokens.jwtToken;
    apolloClientData.csrfToken = authTokens.csrfToken;
  }
  return apolloClientData.apolloClient;
};
