import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Label } from '../Typography';
import colors from '../colors';

const Textarea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 11px 15px;
  font-family: Lato, serif;
  font-size: ${props => (props.compact ? '14px' : '16px')};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: ${colors.darkGray};
  border-color: ${colors.gray};
  outline: none;

  &:focus {
    border-color: ${colors.brand};
  }

  ::placeholder {
    color: ${colors.slateGray};
    font-style: italic;
  }
`;

const StyledTextarea = props => {
  const { style, label, onChange } = props;
  return (
    <div style={{ ...style }}>
      {label && <Label style={{ marginBottom: 3 }}>{label}</Label>}
      <Textarea {...props} onChange={e => onChange(e.target.value)} />
    </div>
  );
};

StyledTextarea.propTypes = {
  compact: PropTypes.bool,
  style: PropTypes.shape({}),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

StyledTextarea.defaultProps = {
  compact: false,
  style: {},
  label: '',
};

export default StyledTextarea;
