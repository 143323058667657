import React, { useMemo } from 'react';
import Link from 'next/link';
import config from '@cardash/config';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import colors from '../../colors';
import useMainContext from '../../../hooks/main-provider';
import { isSfacOrganization } from '../../../utils';

const pathsToIgnore = ['/', '/oil-change-pricing'];
const MenuSidebar = ({ pages }) => {
  const { viewMode, selectedShop, selectedOrganization, viewModeDataLoading } = useMainContext();
  const pathsToIgnoreForSidebar = useMemo(
    () => (!isSfacOrganization(selectedOrganization?.id) ? [...pathsToIgnore, '/chat'] : [...pathsToIgnore]),
    [selectedOrganization]
  );

  const sidebarItems = useMemo(
    () => pages.filter(item => !pathsToIgnoreForSidebar.includes(item.path)),
    [pages, pathsToIgnoreForSidebar]
  );

  const isLocationViewMode = useMemo(() => viewMode === config.viewModes.Location, [viewMode]);

  if (viewModeDataLoading) {
    return (
      <div
        style={{
          minWidth: '150px',
        }}
      >
        <div
          style={{
            minWidth: '150px',
            backgroundColor: colors.brand,
            height: '100%',
            minHeight: '100vh',
            padding: '100px 10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Spinner color="light" />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        minWidth: '150px',
      }}
    >
      <div
        style={{
          minWidth: '150px',
          backgroundColor: colors.brand,
          height: '100%',
          minHeight: '100vh',
          padding: '20px 10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <>
          <Link
            style={{
              margin: '8px',
              color: '#fff',
            }}
            href={isLocationViewMode ? `/?locationId=${selectedShop?.id}` : '/'}
          >
            Home
          </Link>
          {sidebarItems.map(page => (
            <Link
              style={{
                margin: '8px',
                color: '#fff',
              }}
              key={page.path}
              href={isLocationViewMode ? `${page.path}?locationId=${selectedShop?.id}` : `${page.path}`}
            >
              {page.pageName}
            </Link>
          ))}
        </>
      </div>
    </div>
  );
};

MenuSidebar.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MenuSidebar;
