import React, { useCallback, useMemo, useState } from 'react';
import { Col, Table } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '..';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_ORDER_TYPE = 'desc';

const SortButtonsContainer = styled.div`
  svg {
    cursor: pointer;
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }

    &.selected {
      opacity: 1;
    }
  }
`;

const TableComponent = ({
  listData,
  renderTableRow,
  tableHeaders,
  paginate,
  pageSize,
  sortedBy,
  order,
  onSortChange,
  manualSort,
}) => {
  const [page, setPage] = useState(0);

  const defaultSortedColumn = useMemo(() => {
    if (sortedBy) {
      return sortedBy;
    }
    const firstSortableColumn = tableHeaders.find(header => header.sortable);

    if (firstSortableColumn) {
      return firstSortableColumn.accessor || (firstSortableColumn.label || firstSortableColumn || '').toLowerCase();
    }
    return '';
  }, [sortedBy, tableHeaders]);
  const [sortedColumn, setSortedColumn] = useState(defaultSortedColumn);
  const [orderType, setOrderType] = useState(order || DEFAULT_ORDER_TYPE);

  const tableData = useMemo(
    () =>
      sortedColumn && !manualSort
        ? [...listData].sort((a, b) => {
            if (a[sortedColumn] > b[sortedColumn]) {
              return orderType === 'desc' ? 1 : -1;
            }
            if (a[sortedColumn] < b[sortedColumn]) {
              return orderType === 'desc' ? -1 : 1;
            }
            return 0;
          })
        : listData,
    [listData, sortedColumn, orderType, manualSort]
  );

  const renderTableRows = useCallback(() => {
    let data = tableData;

    if (paginate) {
      const pageItemsSize = pageSize || DEFAULT_PAGE_SIZE;
      const startIndex = page === 0 ? 0 : page * pageItemsSize;
      data = tableData.slice(startIndex, startIndex + pageItemsSize);
    }

    if (!data || !data.length) {
      if (page !== 0) {
        setPage(page - 1);
      }
      return (
        <tr>
          <td colSpan={tableHeaders.length}>There is no data to show.</td>
        </tr>
      );
    }

    return data.map(item => {
      return <tr key={item.id}>{renderTableRow(item)}</tr>;
    });
  }, [tableData, paginate, pageSize, page, tableHeaders.length, renderTableRow]);

  const handlePageClick = useCallback(data => {
    setPage(data.selected);
  }, []);

  const renderPagination = useCallback(() => {
    const pageCount = Math.ceil(tableData.length / (pageSize || DEFAULT_PAGE_SIZE));

    if (pageCount > 1) {
      return (
        <Col container alignItems="center" justify="flex-end">
          <ReactPaginate
            previousLabel="PREVIOUS PAGE"
            nextLabel="NEXT PAGE"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
            initialPage={0}
            disableInitialCallback
          />
        </Col>
      );
    }

    return null;
  }, [tableData.length, pageSize, handlePageClick]);

  const handleSortChange = useCallback(
    (key, type) => {
      if (key === sortedColumn && orderType === type) {
        return;
      }

      if (onSortChange && manualSort) {
        onSortChange(key, type);
      }

      setSortedColumn(key);
      setOrderType(type);
    },
    [sortedColumn, orderType, onSortChange, manualSort]
  );

  const renderSortButtons = useCallback(
    item => {
      if (!item || !item.sortable) {
        return;
      }

      const itemKey = item.accessor || (item.label || item || '').toLowerCase();

      const isSortedByCurrentItem = sortedColumn === itemKey;

      // eslint-disable-next-line consistent-return
      return (
        <SortButtonsContainer className="d-flex flex-column align-items-center ms-2">
          <FontAwesomeIcon
            icon={faAngleUp}
            className={`${isSortedByCurrentItem && orderType === 'desc' ? 'selected' : ''} `}
            onClick={() => handleSortChange(itemKey, 'desc')}
          />
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`${isSortedByCurrentItem && orderType === 'asc' ? 'selected' : ''} `}
            onClick={() => handleSortChange(itemKey, 'asc')}
          />
        </SortButtonsContainer>
      );
    },
    [sortedColumn, orderType, handleSortChange]
  );

  return (
    <>
      {tableData ? (
        <>
          <Table responsive striped>
            <thead>
              <tr>
                {tableHeaders.map(header => (
                  <th key={_.uniqueId()}>
                    <div className="d-flex align-items-center">
                      {(header && header.label) || header || ''}
                      {header && header.sortable && renderSortButtons(header)}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
          {paginate && renderPagination()}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

TableComponent.propTypes = {
  listData: PropTypes.arrayOf(PropTypes.any).isRequired,
  renderTableRow: PropTypes.func.isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.any).isRequired,
  paginate: PropTypes.bool,
  manualSort: PropTypes.bool,
  pageSize: PropTypes.number,
  sortedBy: PropTypes.string,
  order: PropTypes.string,
  onSortChange: PropTypes.func,
};

TableComponent.defaultProps = {
  paginate: false,
  manualSort: false,
  pageSize: DEFAULT_PAGE_SIZE,
  sortedBy: undefined,
  order: undefined,
  onSortChange: undefined,
};

export default TableComponent;
