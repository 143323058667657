import React from 'react';
import style from './styles';
import Progress from '../Progress';

let interval;
class ServiceCenterLoading extends React.Component {
  state = {
    percentage: 0.2,
  };

  componentDidMount() {
    interval = setInterval(this.doLoading, 200);
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  doLoading = () => {
    this.setState(prevState => ({
      percentage: prevState.percentage <= 0.9 ? prevState.percentage + 0.1 : prevState.percentage,
    }));
  };

  render() {
    const { percentage } = this.state;
    const { embedded } = this.state;
    return (
      <div
        className="loading"
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.stopPropagation();
          }
        }}
        onClick={e => e.stopPropagation()}
        style={{
          ...style.loading,
          ...(embedded && style.embedded),
        }}
      >
        <Progress progress={percentage} />
      </div>
    );
  }
}

export default ServiceCenterLoading;
