import React from 'react';
import styled from 'styled-components';
import UserSettings from './UserSettings';

const Container = styled.div`
  display: flex;
  background: #412473 !important;
  width: 100%;
  height: 55px;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(77, 74, 74, 0.3);
`;

const TopNavBar = () => {
  return (
    <Container>
      <UserSettings />
    </Container>
  );
};

export default TopNavBar;
