import PropTypes from 'prop-types';
import styled from 'styled-components';

import MediaQueries from '../../MediaQueries';

const centered = `
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Section = styled.section`
  width: 100%;
  padding: ${props => (props.noMobilePadding ? 0 : '25px')};
  ${props => (props.center ? centered : '')};

  ${MediaQueries.notMobile} {
    margin: 0 25px;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '1080px')};
    padding: 25px;
  }
`;

Section.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
  noMobilePadding: PropTypes.bool,
};

Section.defaultProps = {
  style: {},
  noPadding: false,
};

export default Section;
