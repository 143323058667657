function formatPhone(phoneNumber) {
  if (!phoneNumber || !(typeof phoneNumber === 'string' || typeof phoneNumber === 'number')) {
    return '';
  }
  // Replace all non-digit characters
  let cleanNumber = String(phoneNumber).replace(/[^\d]/g, '');

  // Check if cleanNumber starts with 1 and its length to determine if the leading 1 should be removed
  const initialLength = cleanNumber.length;
  cleanNumber = cleanNumber.replace(/^1/, '');
  const wasLeadingOneRemoved = initialLength !== cleanNumber.length;

  // If after removing non-digit characters (and possibly a leading 1), the number is 11 digits long, and
  // a leading 1 was not removed by the replace operation, remove the leading 1
  if (cleanNumber.length === 11 && !wasLeadingOneRemoved) {
    cleanNumber = cleanNumber.substring(1);
  }

  // If the remaining number is not exactly 10 digits, return an empty string
  if (cleanNumber.length !== 10) {
    return '';
  }

  // Format to ###-###-####
  return `${cleanNumber.substring(0, 3)}-${cleanNumber.substring(3, 6)}-${cleanNumber.substring(6)}`;
}

export default formatPhone;
