import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../../colors';

const AutocompleteContainer = styled.div`
  position: absolute;
  top: 44px;
  background-color: white;
  border: 1px solid ${colors.brand};
  border-top: 0;
  width: 100%;
`;

const ItemContainer = styled.p`
  padding: 7px;
  cursor: pointer;

  ${props => (props.isHighlighted ? `background-color: ${colors.violet};` : '')}
`;

const Autocomplete = ({ isVisible, children, ...props }) => {
  if (!isVisible || children.length === 0) {
    return null;
  }

  return <AutocompleteContainer {...props}>{children}</AutocompleteContainer>;
};

Autocomplete.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

class Item extends React.PureComponent {
  static propTypes = {
    data: PropTypes.exact({
      text: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    isHighlighted: PropTypes.bool,
    updateAutocompleteSelectIndex: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
  };

  static defaultProps = {
    isHighlighted: false,
  };

  onMouseDown = () => {
    const { onClick, data } = this.props;
    onClick(data);
  };

  onMouseEnter = () => {
    const { updateAutocompleteSelectIndex, index } = this.props;
    updateAutocompleteSelectIndex(index);
  };

  render() {
    const { data, isHighlighted, ...props } = this.props;
    const { text } = data;

    return (
      <ItemContainer
        {...props}
        data-test="autocomplete-item"
        isHighlighted={isHighlighted}
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
      >
        {text}
      </ItemContainer>
    );
  }
}

Autocomplete.Item = Item;
export default Autocomplete;
