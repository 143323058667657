export default {
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',
    padding: 25,
  },
  embedded: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
};
