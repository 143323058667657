import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Row from '../../LayoutComponents/Row';

const HintBox = styled.div`
  padding-left: 8px;
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 100;
  background-color: #000;
  width: 120px;
  padding: 3px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
`;

class Hint extends React.Component {
  state = {
    active: false,
  };

  render() {
    const { text, style } = this.props;
    const { active } = this.state;
    return (
      <HintBox
        style={style}
        onMouseEnter={() => {
          this.setState({ active: true });
        }}
        onMouseLeave={() => this.setState({ active: false })}
      >
        <Row>
          <FontAwesomeIcon icon={faQuestionCircle} />
          {active && <Tooltip>{text}</Tooltip>}
        </Row>
      </HintBox>
    );
  }
}

Hint.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

Hint.defaultProps = {
  style: {},
};

export default Hint;
