import styled from 'styled-components';
import MediaQueries from '../MediaQueries';
import colors from '../colors';

const H1 = styled.h1`
  font-family: Lato, serif;
  font-weight: 900;
  font-style: normal;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: ${props => (props.color ? props.color : colors.darkGray)};
  font-size: 26px;
  line-height: 1.27;
  letter-spacing: 0.5px;
  text-align: center;
  max-width: 100%;

  ${MediaQueries.notMobile} {
    font-size: 32px;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.7px;
  }
`;

export default H1;
