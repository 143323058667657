import React from 'react';
import PropTypes from 'prop-types';
import trackEvent from '../analytics';

class ClickableDiv extends React.PureComponent {
  static propTypes = {
    eventNameOnClick: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    // Makes this element tabbable for screen readers
    tabIndex: PropTypes.oneOf(['0']).isRequired,
    // Makes this element interactable for screen readers
    onKeyPress: PropTypes.func,
  };

  static defaultProps = {
    onKeyPress: null,
  };

  onKeyPress = e => {
    const { onKeyPress } = this.props;
    if (onKeyPress) {
      onKeyPress(e);
      return;
    }

    if (e.key === 'Enter') {
      this.onClick(e);
    }
  };

  onClick = (...args) => {
    const { eventNameOnClick, onClick } = this.props;
    trackEvent(eventNameOnClick);
    onClick(...args);
  };

  render() {
    const { children } = this.props;

    return (
      <div {...this.props} onKeyPress={this.onKeyPress} onClick={this.onClick}>
        {children}
      </div>
    );
  }
}

export default ClickableDiv;
