import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  flex: 0 1 auto;
`;
// IE requires 0 1 auto: https://stackoverflow.com/questions/21600345/flexbox-and-internet-explorer-11-displayflex-in-html

Container.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};

Container.defaultProps = {
  style: {},
};

export default Container;
