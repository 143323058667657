import styled from 'styled-components';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CancelButton = styled(Button)`
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '25px')};
  user-select: none;
  transition: all 0.2s ease;
  min-height: 36px;
  height: auto;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  font-family: Lato, serif;
  padding: 10px 25px;
`;

export const IconButton = styled(FontAwesomeIcon)`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export default {
  CancelButton,
  IconButton,
};
