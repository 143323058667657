import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import Error from '../Error';
import Toastr from '../Toastr';

const MutationForm = ({ mutation, onSubmit, children, hideError, ...props }) => (
  <Mutation
    mutation={mutation}
    {...props}
    onCompleted={() => Toastr.success('Succeed')}
    onError={error => Toastr.error((error && error.message) || 'Something went wrong, please try again')}
  >
    {(fn, { loading, error }) => {
      return (
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit(fn);
          }}
          onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
          style={{ flex: 1 }}
        >
          {children({ loading, error })}
          {!hideError && <Error error={error} />}
        </form>
      );
    }}
  </Mutation>
);

MutationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  mutation: PropTypes.any.isRequired,
  hideError: PropTypes.bool,
};

MutationForm.defaultProps = {
  hideError: false,
};

export default MutationForm;
