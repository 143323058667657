import styled from 'styled-components';
import Select from 'react-select/dist/react-select.cjs';

export const StyledSelect = styled(Select)`
  font-family: Lato, serif;
  font-style: normal;
  font-stretch: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  width: 100%;

  .side-menu-select__value-container {
    padding: 0 2px;
  }

  .side-menu-select__control {
    min-height: 24px;
    height: 24px;
    width: 100%;
  }

  .side-menu-select__indicator {
    padding: 0;

    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
