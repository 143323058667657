import fetch from 'isomorphic-unfetch';
import config from '@cardash/config';

const graphqlFetch = async (query, variables = undefined, authTokens) => {
  const cookies = document.cookie
    .split('; ')
    .map(c => {
      return c.split('=');
    })
    .reduce((acc, curr) => {
      const [key, val] = curr;
      acc[key] = val;
      return acc;
    }, {});

  return (
    await fetch(config.graphql.url, {
      method: config.graphql.method,
      headers: {
        ...config.graphql.headers,
        'cardash-jwt': cookies.jwtToken || authTokens.jwtToken,
        authorization: `Token ${cookies.csrfToken}`,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
      compress: config.graphql.compress,
    })
  ).json();
};

const query = async (graphqlQuery, variables = undefined, authTokens = {}) => {
  const json = await graphqlFetch(graphqlQuery, variables, authTokens);

  return json;
};

export default query;
