import { getColorCodes } from './getColorCodes';

const yellowColor = '#ffc107';
const greenColor = '#28a745';
const lightRedColor = '#FF6666';
const redColor = '#cc0000';

const gradeMap = {
  'A+': [27, 27],
  A: [25, 26],
  'A-': [23, 24],
  'B+': [22, 22],
  B: [20, 21],
  'B-': [19, 19],
  C: [17, 18],
  D: [15, 16],
  F: [0, 14],
};

const getPointsFromColor = (color, pointsMap) => {
  switch (color) {
    case greenColor:
      return pointsMap.green;
    case yellowColor:
      return pointsMap.yellow;
    case lightRedColor:
      return pointsMap.red;
    case redColor:
      return pointsMap.darkRed || 0;
    default:
      return 0;
  }
};

const calculateHealthCheckPoints = (
  capacityValue,
  avgTimeOnSiteValue,
  oldestVehicleOnSiteValue,
  techsLiftsValue,
  vehicleOnSiteValue,
  maxAppointmentsTechs,
  openDays
) => {
  const capacityColor = getColorCodes.getCapacityColor(capacityValue);
  const avgTimeOnSiteColor = getColorCodes.getAvgTimeOnSiteValueColor(avgTimeOnSiteValue);
  const oldestVehicleOnSiteColor = getColorCodes.getOldestVehicleOnSiteValueColor(oldestVehicleOnSiteValue);
  const techsLiftsColor = getColorCodes.getTechsLiftsValueColor(techsLiftsValue);
  const vehicleOnSiteColor = getColorCodes.getVehicleOnSiteValueColor(vehicleOnSiteValue);
  const openDaysColor = getColorCodes.getOpenDaysValueColor(openDays);
  const maxAppointmentTechsColor = getColorCodes.getMaxAppointmentTechsValueColor(maxAppointmentsTechs);

  const capacityPointsMap = { green: 3, yellow: 2, lightRed: 1, red: 0 };
  const avgTimeOnSitePointsMap = { green: 5, yellow: 3, red: 0 };
  const oldestVehicleOnSitePointsMap = { green: 2, yellow: 1, red: 0 };
  const techsLiftsPointsMap = { green: 3, yellow: 2, red: 0 };
  const vehicleOnSitePointsMap = { green: 5, yellow: 3, red: 0 };
  const openDaysPointsMap = { green: 5, yellow: 2, red: 0 };
  const maxAppointmentTechsPointsMap = { green: 4, yellow: 2, red: 0 };

  const totalPoints =
    getPointsFromColor(capacityColor, capacityPointsMap) +
    getPointsFromColor(avgTimeOnSiteColor, avgTimeOnSitePointsMap) +
    getPointsFromColor(oldestVehicleOnSiteColor, oldestVehicleOnSitePointsMap) +
    getPointsFromColor(techsLiftsColor, techsLiftsPointsMap) +
    getPointsFromColor(vehicleOnSiteColor, vehicleOnSitePointsMap) +
    getPointsFromColor(openDaysColor, openDaysPointsMap) +
    getPointsFromColor(maxAppointmentTechsColor, maxAppointmentTechsPointsMap);

  return totalPoints;
};

const getHealthCheckGrade = points => {
  return Object.keys(gradeMap).find(grade => points >= gradeMap[grade][0] && points <= gradeMap[grade][1]);
};

export const calculateHealthCheckWithGrade = (
  capacityValue,
  avgTimeOnSiteValue,
  oldestVehicleOnSiteValue,
  techsLiftsValue,
  vehicleOnSiteValue,
  maxAppointmentsTechs,
  openDays
) => {
  const healthCheckPoints = calculateHealthCheckPoints(
    capacityValue,
    avgTimeOnSiteValue,
    oldestVehicleOnSiteValue,
    techsLiftsValue,
    vehicleOnSiteValue,
    maxAppointmentsTechs,
    openDays
  );
  const grade = getHealthCheckGrade(healthCheckPoints);
  return { healthCheckPoints, grade };
};
