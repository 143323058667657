export default {
  brand: '#673AB7',
  brandDark: '#412473',
  brandLight: '#9879cd',
  hover: '#5A339E',
  darkGray: '#5d5d75',
  gray: '#6c6d88',
  lightGray: '#8b90ad',
  slateGray: '#acb0c7',
  veryLightGray: '#ced2e0',
  faintGray: '#e4e6ee',
  violet: '#f0f1f6',
  lightestGray: '#f7f7f9',
  lilac: '#e6dff3',
  lightViolet: '#f3f0fa',
  veryLightViolet: '#faf8fd',
  bluish: '#2db0dc',
  success: '#56B536',
  error: '#E75454',
  warning: '#DF9519',
  white: '#fff',
};
