// TODO do this
// const upgradeAuth = async () => {
//   const response = await fetch(`${process.env.AUTH_HOST}/upgradeCredentials`, {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       Cache: 'no-cache',
//       'Content-Type': 'application/json',
//       authorization: `Token ${csrfToken}`,
//     },
//     credentials: 'include',
//   }).then(r => r.json());
//
//   ({ csrfToken, } = response)
//   setVersion(CURRENT_VERSION)
// }
import { signOut } from 'next-auth/react';

const refreshJwtToken = (refreshToken, csrfToken) => {
  return fetch(`${process.env.AUTH_HOST}/refreshJWTToken`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Cache: 'no-cache',
      'Content-Type': 'application/json',
      authorization: `Token ${csrfToken}`,
    },
    credentials: 'include',
    body: JSON.stringify({ refreshToken }),
  }).then(r => r.json());
};

const login = async googleToken => {
  const verifyResponse = await fetch(`${process.env.AUTH_HOST}/verify`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Cache: 'no-cache',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      token: googleToken,
    }),
  });
  const contentType = verifyResponse.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return verifyResponse.json();
  }
  return { error: verifyResponse.statusText };
};

const loginWithEmail = async ({ email, password, token }) => {
  console.log('token___________________________????', token);
  const verifyResponse = await fetch(`${process.env.AUTH_HOST}/signin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Cache: 'no-cache',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      email,
      password,
      token,
      type: 'Admin',
    }),
  });
  const contentType = verifyResponse.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return verifyResponse.json();
  }
  return { error: verifyResponse.statusText };
};

const logout = async () => {
  await fetch(`${process.env.AUTH_HOST}/logout`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Cache: 'no-cache',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  await signOut();
};

module.exports = {
  refreshJwtToken,
  login,
  logout,
  loginWithEmail,
};
