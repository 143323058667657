import styled from 'styled-components';
import colors from '../colors';

const center = `
  text-align: center;
`;

const Label = styled.label`
  font-family: Lato, serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  max-width: 100%;
  display: block;
  margin-top: 0;
  color: ${props => (props.color ? props.color : colors.darkGray)};
  ${props => (props.center ? center : '')}
`;

export default Label;
