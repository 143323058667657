import styled from 'styled-components';
import colors from '../colors';

export const smallBodySize = `
  font-size: 14px;
  letter-spacing: 0.2px;
`;

const mediumBodySize = `
  font-size: 16px;
  letter-spacing: 0.2px;
`;

export const largeBodySize = `
  font-size: 18px;
  letter-spacing: 0.3px;
`;

const bold = `
  font-weight: bold;
`;

const center = `
  text-align: center;
`;

export const BodyStyle = `
  font-family: Lato, serif;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  line-height: 1.5;
  word-break: break-word;
  max-width: 100%;
  ${largeBodySize}
`;

const Body = styled.p`
  ${BodyStyle};
  margin-top: 0;
  margin-bottom: ${props => (props.nomargin ? 0 : '0.5em')};
  color: ${props => (props.color ? props.color : colors.darkGray)};
  ${props => (props.center ? center : '')}
  ${props => (props.small ? smallBodySize : '')}
  ${props => (props.medium ? mediumBodySize : '')}
  ${props => (props.bold ? bold : '')}
  ${props => (props.size ? `font-size: ${props.size}px;` : '')}
`;

export default Body;
