import React, { useEffect } from 'react';
import { SessionProvider, useSession } from 'next-auth/react';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.min.css';
import Layout from '../components/Layout/Layout';
import { MainContextProvider } from '../hooks/main-provider';
import * as Sentry from '@sentry/nextjs';
import { WithApolloClient } from '../graphql/withApolloClient';

const AppComponentsWrapper = ({ Component, pageProps }) => {
  const { data: session } = useSession();
  useEffect(() => {
    if (session?.user) {
      Sentry.setUser({
        id: session.user.id,
        email: session.user.email,
        name: session.user.name,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [session?.user]);

  return (
    <Sentry.ErrorBoundary>
      <WithApolloClient>
        <MainContextProvider>
          <Layout {...pageProps}>
            <Component {...pageProps} />
          </Layout>
        </MainContextProvider>
      </WithApolloClient>
    </Sentry.ErrorBoundary>
  );
};

const MyApp = props => (
  <ReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_ID}>
    <SessionProvider session={props.session}>
      <AppComponentsWrapper {...props} />
    </SessionProvider>
  </ReCaptchaProvider>
);

export default MyApp;
