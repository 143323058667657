import PropTypes from 'prop-types';
import styled from 'styled-components';

// Responsive image styling
const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

Image.propTypes = {
  // Enforce the alt prop so we support screen readers and
  // SEO bots to understand the image content
  alt: PropTypes.string.isRequired,
};

export default Image;
