function formatCustomerName(customer) {
  const { firstName = '', lastName = '', companyName = '' } = customer || {};
  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  // Check if there is a fullName. If so, format with companyName in parentheses (if companyName exists).
  // If no fullName exists but companyName does, return just the companyName.
  return fullName ? (companyName ? `${fullName} (${companyName})` : fullName) : companyName;
}

export default formatCustomerName;
