import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from './Autocomplete';
import InvalidTooltip from './InvalidTooltip';
import { InputContainer, StyledInput } from './styledComponents';
import { Body } from '../../Typography';

/**
 * Standard input component for web forms
 *
 * @param {node} value (required) - the current value shown
 * @param {function} onChange (required) - change handler
 * @param {boolean} invalid - if true, shows error message and highlights input as red
 * @param {function} validate - dynamic validation function triggered when Input mounts,
 * focuses, changes, or blurs
 * @param {string} errorText - the text that shows when there is an error
 * @param {string|node} prefix - a string or element to show to the left of the input
 * @param {string} placeholder - the text before any input value is present
 * @param {boolean} disabled - grays out input and prevents user from changing it
 * @param {boolean} autoFocus - allows autoFocus / immediate typing when component appears
 * @param {object} style - styling for overall container div
 * @param {object} inputContainerStyle - styling for the bordered div housing prefix and input
 * @param {object} prefixStyle - styling for prefix
 * @param {object} inputStyle - styling for input component itself
 * @param {object} errorTextStyle - styling for error message
 * @param {function} onFocus - focus event handler
 * @param {function} onKeyDown - keyDown event handler
 * @param {function} onBlur - blur event handler
 * @param {string} type - input type (default 'text')
 * @param {string} name - input name
 */
class TextInput extends React.Component {
  static propTypes = {
    value: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    validate: PropTypes.func,
    errorText: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    style: PropTypes.shape({}),
    inputContainerStyle: PropTypes.shape({}),
    inputStyle: PropTypes.shape({}),
    errorTextStyle: PropTypes.shape({}),
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    testName: PropTypes.string,
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    smallOnMobile: PropTypes.bool,
    autocompleteData: PropTypes.arrayOf(
      PropTypes.exact({
        text: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      })
    ),
    onSelectAutocomplete: PropTypes.func,
    className: PropTypes.string,
    compact: PropTypes.bool,
  };

  static defaultProps = {
    invalid: false,
    validate: () => {},
    errorText: 'Invalid input',
    placeholder: '',
    disabled: false,
    autoFocus: false,
    style: {},
    inputContainerStyle: {},
    inputStyle: {},
    errorTextStyle: {},
    onFocus: () => {},
    onKeyDown: () => {},
    onBlur: () => {},
    type: 'text',
    name: null,
    testName: null,
    maxLength: '524288',
    leftIcon: null,
    rightIcon: null,
    smallOnMobile: false,
    autocompleteData: [],
    onSelectAutocomplete: f => f,
    className: undefined,
    compact: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isFocused: props.autoFocus,
      autocompleteSelectIndex: 0,
    };
  }

  componentDidMount() {
    // Validates on mount in case initial value is invalid, but not if blank
    const { value, validate } = this.props;
    if (value) validate(value);
  }

  handleKeyDown = e => {
    const { onKeyDown, autocompleteData, onSelectAutocomplete } = this.props;
    const { autocompleteSelectIndex, isFocused } = this.state;

    // Some fancy logic to handle when the user presses the arrow keys to navigate
    // the autocomplete dropdown
    if (autocompleteData.length > 0 && isFocused) {
      if (e.key === 'ArrowDown') {
        this.setState({
          autocompleteSelectIndex: Math.min(autocompleteData.length - 1, autocompleteSelectIndex + 1),
        });
      } else if (e.key === 'ArrowUp') {
        this.setState({
          autocompleteSelectIndex: Math.max(0, autocompleteSelectIndex - 1),
        });
      } else if (
        e.key === 'Enter' &&
        autocompleteSelectIndex >= 0 &&
        autocompleteSelectIndex <= autocompleteData.length - 1
      ) {
        const selectedData = autocompleteData[autocompleteSelectIndex];
        onSelectAutocomplete(selectedData);
        // Forcibly lose focus so we hide the dropdown
        this.setState({ autocompleteSelectIndex: 0, isFocused: false });
        e.preventDefault();
      }
    }

    // Handles edge-case where user presses enter on autocomplete, we hide the dropdown
    // and then they enter more characters
    if (!isFocused && e.key !== 'Enter') {
      this.setState({ isFocused: true });
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  updateAutocompleteSelectIndex = autocompleteSelectIndex => {
    this.setState({ autocompleteSelectIndex });
  };

  render() {
    const {
      className,
      disabled,
      maxLength,
      style,
      invalid,
      errorText,
      inputContainerStyle,
      inputStyle,
      placeholder,
      value,
      autoFocus,
      validate,
      onFocus,
      onChange,
      onBlur,
      errorTextStyle,
      type,
      name,
      testName,
      leftIcon,
      rightIcon,
      smallOnMobile,
      autocompleteData,
      onSelectAutocomplete,
      compact,
    } = this.props;

    const { autocompleteSelectIndex, isFocused } = this.state;

    const disabledStyle = disabled
      ? {
          backgroundColor: '#F5F5F5',
          pointerEvents: 'none',
        }
      : {};

    return (
      <div className={className} style={{ position: 'relative', ...style }}>
        <InputContainer
          compact={compact}
          className="inputContainer"
          invalid={invalid}
          isFocused={isFocused}
          style={inputContainerStyle}
        >
          <StyledInput
            compact={compact}
            data-test={testName}
            maxLength={maxLength}
            hasLeftIcon={!!leftIcon}
            hasRightIcon={!!rightIcon}
            smallOnMobile={smallOnMobile}
            style={{ ...inputStyle, ...disabledStyle }}
            type={type}
            name={name}
            className="placeholder_light"
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            onFocus={e => {
              // Validate current value but only if present
              if (e.target.value) validate(e.target.value);
              onFocus(e);
              this.setState({ isFocused: true });
              e.target.select();
            }}
            onKeyDown={this.handleKeyDown}
            onChange={e => {
              onChange(e.target.value, name);
              validate(e.target.value);
            }}
            onBlur={e => {
              this.setState({ isFocused: false });
              validate(e.target.value);
              onBlur(e);
            }}
          />
          {!!leftIcon && <Body style={{ position: 'absolute', left: 15, top: 10 }}>{leftIcon}</Body>}
          {!!rightIcon && <Body style={{ position: 'absolute', right: 15, top: 10 }}>{rightIcon}</Body>}
        </InputContainer>
        <InvalidTooltip isOpen={invalid} style={errorTextStyle} text={errorText} />
        <Autocomplete isVisible={isFocused}>
          {autocompleteData.map((d, i) => (
            <Autocomplete.Item
              key={d.text}
              data={d}
              index={i}
              updateAutocompleteSelectIndex={this.updateAutocompleteSelectIndex}
              isHighlighted={i === autocompleteSelectIndex}
              onClick={onSelectAutocomplete}
            />
          ))}
        </Autocomplete>
      </div>
    );
  }
}

export default TextInput;
