import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../colors';
import { Label } from '../../Typography';

const Select = styled.select`
  background: white;
  margin-bottom: ${props => (props.compact ? '10px' : '20px')};
  border-radius: 3px;
  font-family: Lato, serif;
  font-style: normal;
  font-stretch: normal;
  font-size: ${props => (props.compact ? '12px' : '16px')};
  font-weight: 400;
  line-height: ${props => (props.compact ? 1.2 : 1.5)};
  letter-spacing: 0.3px;
  color: ${colors.darkGray};
  height: ${props => (props.compact ? '24px' : '42px')};
  max-width: 100%;
  padding: 0 12px;
`;

const Option = styled.option`
  font-family: Lato, serif;
  font-style: normal;
  font-stretch: normal;
  font-size: ${props => (props.compact ? '14px' : '18px')};
  font-weight: 400;
  line-height: ${props => (props.compact ? 1.2 : 1.5)};
  letter-spacing: 0.3px;
  color: ${colors.darkGray};
`;

class Dropdown extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape({}),
    testName: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectStyle: PropTypes.shape({}),
    compact: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: null,
    style: {},
    label: null,
    testName: null,
    value: '',
    selectStyle: {},
    compact: false,
  };

  onChange = e => {
    const { onChange } = this.props;
    onChange(e.target.value);
  };

  render() {
    const { label, options, placeholder, value, selectStyle, style, testName, compact } = this.props;
    // We need to check for falsey values to ensure we show the placeholder properly
    const safeValue = value === 0 ? value : value || '';

    return (
      <div style={style}>
        {label && <Label style={{ marginBottom: 3 }}>{label}</Label>}
        <Select compact={compact} style={selectStyle} data-test={testName} value={safeValue} onChange={this.onChange}>
          {placeholder && (
            <Option compact={compact} value="" disabled>
              {placeholder}
            </Option>
          )}
          {options.map(option => (
            <Option compact={compact} key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default Dropdown;
