import styled from 'styled-components';

import MediaQueries from '../MediaQueries';

const HideOnMobile = styled.section`
  display: none;
  ${MediaQueries.notMobile} {
    display: block;
  }
`;
// IE11 does not play nice with display initial

export default HideOnMobile;
