import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors';
import Body from '../../Typography/Body';
import trackEvent from '../../analytics';
import MediaQueries from '../../MediaQueries';

const flat = `
  color: ${colors.brand};
  background-color: #fff;
`;

const flatHover = `
  opacity: .90;
`;

const stdHover = `
  background-color: ${colors.hover};
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  white-space: nowrap;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  min-height: 36px;
  height: auto;
  width: ${props => (!props.small ? '287px' : '')};
  max-width: 100%;
  padding: 10px;
  color: #fff;
  background-color: ${props => props.buttonColor || colors.brand};
  border: 1px solid ${props => props.buttonColor || colors.brand};
  ${props => (props.flat ? flat : '')}
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  font-weight: 900;

  ${MediaQueries.notMobile} {
    padding: 10px 25px;
  }

  &:hover {
    box-shadow: 4px 4px 4px -2px rgba(0, 0, 0, 0.2);
    ${props => (props.flat || props.buttonColor ? flatHover : stdHover)}
  }

  &:disabled {
    background-color: ${colors.lilac};
    border-color: ${colors.lilac};

    &:hover {
      box-shadow: none;
      cursor: auto;
    }
  }
`;

class PrimaryButton extends React.Component {
  static propTypes = {
    // TODO: Make this property required so that we have events on all buttons
    eventNameOnClick: PropTypes.string,
    onClick: PropTypes.func,
    buttonColor: PropTypes.string,
  };

  static defaultProps = {
    eventNameOnClick: null,
    buttonColor: null,
    onClick: f => f,
  };

  renderChildren = props => {
    const { children } = props;
    if (typeof children === 'string') {
      return (
        <Body
          style={{ marginBottom: 0, textTransform: 'uppercase' }}
          small
          bold={props.bold}
          color={props.flat ? colors.brand : '#fff'}
        >
          {children}
        </Body>
      );
    }
    return children;
  };

  onClick = (...args) => {
    const { eventNameOnClick, onClick } = this.props;
    if (eventNameOnClick) {
      trackEvent(eventNameOnClick);
    }
    if (onClick) {
      onClick(...args);
    }
  };

  render() {
    return (
      <Button {...this.props} onClick={this.onClick}>
        {this.renderChildren(this.props)}
      </Button>
    );
  }
}

export default PrimaryButton;
