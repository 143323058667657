function formatMoney(money) {
  // Round the money to two decimal places and convert to integer
  const roundedMoney = Math.round(Number(money));

  // Use toLocaleString to add commas and format as currency without decimals
  return roundedMoney.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export default formatMoney;
