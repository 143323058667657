import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import startCase from 'lodash/startCase';
import { Column } from '../LayoutComponents';
import { Label } from '../Typography';

const OPTIONS = [
  {
    label: 'Pacific Time (PT)',
    value: 'America/Los_Angeles',
  },
  {
    label: 'Mountain Time (MT)',
    value: 'America/Denver',
  },
  {
    label: 'Central Time (CT)',
    value: 'America/Chicago',
  },
  {
    label: 'Eastern Time (ET)',
    value: 'America/New_York',
  },
];

const TimezoneProperty = ({ propertyPath, value, onUpdate, invalid, label }) => {
  const pathKeys = propertyPath.split('.');
  const propertyKey = pathKeys[pathKeys.length - 1];
  const defaultValue = OPTIONS.find(el => el.value === value);

  function handleChange(val) {
    onUpdate(propertyPath, val.value);
  }

  return (
    <Column
      style={{
        margin: '8px',
      }}
    >
      <Label>
        {label || startCase(propertyKey)}
        <Select
          styles={{
            control: base =>
              invalid
                ? {
                    ...base,
                    borderColor: '#dc3545',
                  }
                : base,
          }}
          options={OPTIONS}
          placeholder="SELECT TIMEZONE"
          onChange={val => handleChange(val)}
          defaultValue={defaultValue}
        />
      </Label>
    </Column>
  );
};

TimezoneProperty.propTypes = {
  propertyPath: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  label: PropTypes.string,
};

TimezoneProperty.defaultProps = {
  invalid: false,
  label: '',
};

export default TimezoneProperty;
