import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import moment from 'moment';
import * as Sentry from '@sentry/nextjs';

import Body from '../Typography/Body';
import colors from '../colors';

const StyledBody = styled(Body)`
  color: ${colors.error};
`;

const StyledServerError = styled(Body)`
  margin-left: 15px;
`;

class Error extends React.PureComponent {
  static propTypes = {
    error: PropTypes.shape({
      graphQLErrors: PropTypes.arrayOf(
        PropTypes.shape({
          message: PropTypes.string,
        })
      ),
      networkError: PropTypes.shape({
        result: PropTypes.shape({
          errors: PropTypes.arrayOf(
            PropTypes.shape({
              message: PropTypes.string,
            })
          ),
        }),
      }),
    }),
  };
  static defaultProps = {
    error: null,
  };

  componentDidMount() {
    if (this.props.error) {
      Sentry.captureException(this.props.error);
    }
  }

  render() {
    const { error } = this.props;

    if (!error) {
      return null;
    }

    const graphqlError = error.networkError || get(error, 'graphQLErrors[0]') || {};
    const serverStackTrace = get(graphqlError, 'extensions.exception.stacktrace', []);
    const serverErrorPath = graphqlError.path || [];
    const code = get(graphqlError, '.extensions.code');

    let message;
    if (code === 'FORBIDDEN') {
      message = 'You do not have permission to view this.';
    } else if (error.networkError) {
      message = 'Unable to connect to server.';
    } else {
      ({ message } = graphqlError);
    }

    window.console.error(error.graphQLErrors);

    return (
      <React.Fragment>
        <StyledBody>{message}</StyledBody>
        <div style={{ height: 30 }} />
        {serverStackTrace.length > 0 && (
          <React.Fragment>
            {serverStackTrace[0]}
            <StyledServerError>
              {serverStackTrace.slice(1).map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </StyledServerError>
            <div style={{ height: 30 }} />
            Path:
            <StyledServerError>[{serverErrorPath.join(', ')}]</StyledServerError>
            <div style={{ height: 30 }} />
            Timestamp:
            <StyledServerError>
              {moment().format('YYYY-MM-DD hh:mma')}
              <br />
              {moment().unix()}
            </StyledServerError>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Error;
