import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import Error from '../Error';

const QueryWrapper = ({ query, children, ...rest }) => (
  <Query query={query} {...rest}>
    {({ data, loading, error }) => {
      if (error) return <Error error={error} />;
      if (loading) return 'Loading...';
      return children({ data, loading, error });
    }}
  </Query>
);

QueryWrapper.propTypes = {
  query: PropTypes.any.isRequired,
  children: PropTypes.func.isRequired,
};

export default QueryWrapper;
