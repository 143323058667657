import styled from 'styled-components';
import MediaQueries from '../MediaQueries';
import colors from '../colors';

const H2 = styled.h2`
  font-family: Lato, serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  margin-top: 0;
  margin-bottom: 0.5em;
  color: ${props => (props.color ? props.color : colors.darkGray)};
  max-width: 100%;

  ${MediaQueries.notMobile} {
    font-family: Lato, serif;
    font-size: 28px;
    letter-spacing: 0.3px;
  }
`;

export default H2;
