import { useMemo } from 'react';
import useMainContext from './main-provider';

const useOrganizationName = () => {
  const { selectedOrganization } = useMainContext();

  return useMemo(
    () => selectedOrganization && selectedOrganization.name && selectedOrganization.name.toUpperCase(),
    [selectedOrganization]
  );
};

export default useOrganizationName;
