import PropTypes from 'prop-types';
import styled from 'styled-components';

import MediaQueries from '../../MediaQueries';

const Responsive = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  ${MediaQueries.notMobile} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

Responsive.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};

Responsive.defaultProps = {
  style: {},
};

export default Responsive;
