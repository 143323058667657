import React from 'react';
import PropTypes from 'prop-types';
import { InvalidTooltipContainer } from './styledComponents';

/**
 * Error message that appears below input
 *
 * @param {boolean} isOpen (required) - should the tooltip show or not
 * @param {string} text (required) - the error text
 * @param {object} style - tooltip style override
 */
const InvalidTooltip = ({ isOpen, text, style }) =>
  isOpen ? <InvalidTooltipContainer style={style}>{text}</InvalidTooltipContainer> : null;

InvalidTooltip.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

InvalidTooltip.defaultProps = {
  style: {},
};

export default InvalidTooltip;
