import PropTypes from 'prop-types';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

Row.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};

Row.defaultProps = {
  style: {},
};

export default Row;
