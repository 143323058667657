const toastr = require('toastr');

/**
 * Notification Service is for showing the toasts on the right top corner of the screen
 */
class Toastr {
  static setup() {
    toastr.options.preventDuplicates = true;
    toastr.options.closeDuration = 300;
    toastr.options.timeOut = 5000;
    toastr.options.extendedTimeOut = 5000;
    toastr.options.progressBar = false;
    toastr.options.escapeHtml = false;
    toastr.options.closeButton = true;
  }

  static success(message) {
    toastr.success(`<strong>${message}</strong>`);
  }

  static info(message) {
    toastr.info(`<strong>${message}</strong>`);
  }

  static error(message) {
    toastr.error(message);
  }

  static warning(message) {
    toastr.warning(message);
  }
}

Toastr.setup();

module.exports = Toastr;
