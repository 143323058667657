import styled from 'styled-components';
import colors from '../colors';

const center = `
  text-align: center;
`;
const Fine = styled.p`
  font-family: Lato, serif;
  font-style: normal;
  font-stretch: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.3px;
  margin-top: 0;
  max-width: 100%;
  margin-bottom: ${props => (props.nomargin ? 0 : '0.5em')};
  color: ${props => (props.color ? props.color : colors.lightGray)};
  ${props => (props.center ? center : '')}
`;

export default Fine;
