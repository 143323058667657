import config from '@cardash/config';

const trackEvent = (name, properties) => {
  if (config.debugAnalytics) console.log(name, properties); // eslint-disable-line no-console
  const opts = {};
  const analytics = window.analytics || { track: () => {} };
  analytics.track(name, properties, opts);
};

export default trackEvent;
